import { Box, CircularProgress } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export function HasMuseumDelivery({ hasMuseumDelivery }) {
  let chartData;

  if (hasMuseumDelivery) {
    const seriesData = Object.entries(hasMuseumDelivery).map(
      ([wheel, value]) => {
        return {
          name: wheel,
          y: value * 100,
        };
      }
    );

    chartData = {
      chart: {
        type: "pie",
      },
      title: {
        text: "Museum Delivery",
      },
      tooltip: {
        valueSuffix: "%",
        format: "{point.percentage:.1f}%",
      },
      plotOptions: {
        series: {
          allowPointSelect: false,
          cursor: "pointer",
          dataLabels: [
            {
              enabled: true,
              distance: 20,
            },
          ],
        },
      },
      series: [
        {
          name: "Percentage",
          colorByPoint: true,
          data: seriesData,
        },
      ],
    };
  }

  return (
    <Box sx={{ width: "700px", height: "500px" }}>
      {chartData !== undefined ? (
        <HighchartsReact highcharts={Highcharts} options={chartData} />
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}
