import { Box, Typography } from "@mui/material";

export default function Footer() {
  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "16px",
      }}
    >
      <Typography variant="body1">
        Made with ❤️ in Pittsburgh by Matt Spettel
      </Typography>
      <Typography variant="body1">|</Typography>
      <Typography variant="body1">
        Special thanks to rfkirk & Corvette Forum
      </Typography>
      <Typography variant="body1">|</Typography>
      <a href="mailto:mspettel@alumni.cmu.edu">
        <Typography variant="body1">Contact</Typography>
      </a>
    </Box>
  );
}
