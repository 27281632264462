import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import bcrypt from "bcryptjs";
import { useNavigate } from "react-router";

export function CreateAccount({ handleLogin }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const isEmailError =
    email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const isPasswordError = password && password.length < 8;

  const isButtonEnabled =
    email && !isEmailError && password && !isPasswordError;

  const navigate = useNavigate();

  function handleCreateAccount() {
    setIsLoading(true);

    const hash = bcrypt.hashSync(password, 8);

    fetch("https://api.corvettetrack.com/users", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email, passwordHash: hash }),
    })
      .then((resp) => resp.json())
      .then((json) => {
        console.log(json);
        if (json.userId) {
          handleLogin(json.userId);
          navigate("/");
        } else {
          setError(json.error);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setError("Unable to create account. Please contact support.");
      });
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
        flexDirection: "column",
        mt: "32px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "400px",
        }}
      >
        <Typography fontWeight="bold" variant="h5">
          Create Account
        </Typography>
        <Typography variant="body">
          You'll use this login to access your car details and view analytics on
          option choices order timelines.
        </Typography>
        <TextField
          disabled={isLoading}
          error={isEmailError}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mt: "32px" }}
          placeholder="Email"
        />
        <TextField
          disabled={isLoading}
          error={isPasswordError}
          type={"password"}
          helperText={
            isPasswordError ? "Must be at least 8 characters" : undefined
          }
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />

        {isLoading ? (
          <Box sx={{ mt: "32px", display: "flex", justifyContent: "center" }}>
            <CircularProgress color="torchRed" />
          </Box>
        ) : (
          <Button
            onClick={handleCreateAccount}
            sx={{ mt: "32px" }}
            disabled={!isButtonEnabled}
            variant="contained"
            color="torchRed"
          >
            Create Account
          </Button>
        )}
        {error && (
          <Typography sx={{ mt: "16px", color: "red" }}>{error}</Typography>
        )}
      </Box>
    </Box>
  );
}
