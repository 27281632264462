import { Box, Card, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { parseJSONWithDates } from "../../utility/api";
import {
  ArrowForward,
  CheckCircle,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { formatDate } from "../../utility/calendar";

export function OrderTimeline({ userId }) {
  const [timeline, setTimeline] = useState();

  useEffect(() => {
    fetch("https://api.corvettetrack.com/cars/orderTimeline?userId=" + userId, {
      method: "POST",
    })
      .then((resp) => {
        if (resp.status === 200) {
          return resp.text();
        } else {
          throw new Error("Failure status code");
        }
      })
      .then((text) => {
        const json = parseJSONWithDates(text);
        console.log("Timeline:", json);
        if (json.steps["3000"] === null) {
          setTimeline(null);
        } else {
          setTimeline(json);
        }

      })
      .catch((err) => {
        console.log(err);
        setTimeline();
      });
  }, []);

  return (
    <Box>
      {timeline ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{ marginTop: "32px", mb: "16px" }}
              fontWeight="bold"
              variant="h5"
            >
              Order Timeline
            </Typography>
            <Typography>
              We've analyzed{" "}
              <span style={{ fontWeight: "bold" }}>
                {timeline.sampleSize} orders similar
              </span>{" "}
              to yours to estimate your order timeline.
              <br />
              Make sure all information is up to date on the "My Car" page for
              more accurate results.
            </Typography>
          </Box>
          <Card
            elevation={4}
            sx={{
              padding: "32px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "64px",
              gap: "32px",
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Typography fontWeight={"bold"}>3000</Typography>
              <Typography variant="body2">Accepted</Typography>
              {timeline.steps["3000"].isKnown ? (
                <CheckCircle
                  sx={{ my: "8px" }}
                  color="success"
                  fontSize="large"
                />
              ) : (
                <RadioButtonUnchecked sx={{ my: "8px" }} fontSize="large" />
              )}
              <Typography variant="body2">
                {formatDate(timeline.steps["3000"].date)}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="body2" sx={{ color: "#555" }}>
                {Math.round(
                  (timeline.steps["3300"].date - timeline.steps["3000"].date) /
                    1000 /
                    60 /
                    60 /
                    24
                )}{" "}
                days
              </Typography>
              <ArrowForward />
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography fontWeight={"bold"}>3300</Typography>
              <Typography variant="body2">Scheduled</Typography>
              {timeline.steps["3300"].isKnown ? (
                <CheckCircle
                  sx={{ my: "8px" }}
                  color="success"
                  fontSize="large"
                />
              ) : (
                <RadioButtonUnchecked sx={{ my: "8px" }} fontSize="large" />
              )}
              <Typography variant="body2">
                {formatDate(timeline.steps["3300"].date)}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="body2" sx={{ color: "#555" }}>
                {Math.round(
                  (timeline.steps["3400"].date - timeline.steps["3300"].date) /
                    1000 /
                    60 /
                    60 /
                    24
                )}{" "}
                days
              </Typography>
              <ArrowForward />
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography fontWeight={"bold"}>3400</Typography>
              <Typography variant="body2">Broadcast</Typography>
              {timeline.steps["3400"].isKnown ? (
                <CheckCircle
                  sx={{ my: "8px" }}
                  color="success"
                  fontSize="large"
                />
              ) : (
                <RadioButtonUnchecked sx={{ my: "8px" }} fontSize="large" />
              )}
              <Typography variant="body2">
                {formatDate(timeline.steps["3400"].date)}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="body2" sx={{ color: "#555" }}>
                {Math.round(
                  (timeline.steps["3800"].date - timeline.steps["3400"].date) /
                    1000 /
                    60 /
                    60 /
                    24
                )}{" "}
                days
              </Typography>
              <ArrowForward />
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography fontWeight={"bold"}>3800</Typography>
              <Typography variant="body2">Produced</Typography>
              {timeline.steps["3800"].isKnown ? (
                <CheckCircle
                  sx={{ my: "8px" }}
                  color="success"
                  fontSize="large"
                />
              ) : (
                <RadioButtonUnchecked sx={{ my: "8px" }} fontSize="large" />
              )}
              <Typography variant="body2">
                {formatDate(timeline.steps["3800"].date)}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="body2" sx={{ color: "#555" }}>
                {Math.round(
                  (timeline.steps["4000"].date - timeline.steps["3800"].date) /
                    1000 /
                    60 /
                    60 /
                    24
                )}{" "}
                days
              </Typography>
              <ArrowForward />
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography fontWeight={"bold"}>4000</Typography>
              <Typography variant="body2">Ready to Ship</Typography>
              {timeline.steps["4000"].isKnown ? (
                <CheckCircle
                  sx={{ my: "8px" }}
                  color="success"
                  fontSize="large"
                />
              ) : (
                <RadioButtonUnchecked sx={{ my: "8px" }} fontSize="large" />
              )}
              <Typography variant="body2">
                {formatDate(timeline.steps["4000"].date)}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="body2" sx={{ color: "#555" }}>
                {Math.round(
                  (timeline.steps["6000"].date - timeline.steps["4000"].date) /
                    1000 /
                    60 /
                    60 /
                    24
                )}{" "}
                days
              </Typography>
              <ArrowForward />
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography fontWeight={"bold"}>6000</Typography>
              <Typography variant="body2">Delivered</Typography>
              {timeline.steps["6000"].isKnown ? (
                <CheckCircle
                  sx={{ my: "8px" }}
                  color="success"
                  fontSize="large"
                />
              ) : (
                <RadioButtonUnchecked sx={{ my: "8px" }} fontSize="large" />
              )}
              <Typography variant="body2">
                {formatDate(timeline.steps["6000"].date)}
              </Typography>
            </Box>
          </Card>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <CircularProgress color="torchRed" />
          <Typography>
            Make sure you have entered your 3000 status date in the "My Car"
            tab.
          </Typography>
        </Box>
      )}
    </Box>
  );
}
