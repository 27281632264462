import {
  Box,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Fragment, useEffect, useState } from "react";
import {
  exteriorColorCodes,
  nameForExteriorColor,
} from "../../../utility/colors";
import { wheelCodes } from "../../../utility/wheels";
import { parseJSONWithDates } from "../../../utility/api";
import dayjs from "dayjs";

export function MyCar({ userId }) {
  const [car, setCar] = useState();
  const [isSaving, setIsSaving] = useState(false);

  console.log(car);

  const isZ06 = car && "build" in car && car.build.model === "z06";

  const hasOrderDates = car && car.orderDates;

  useEffect(() => {
    fetch("https://api.corvettetrack.com/cars/?userId=" + userId, {
      method: "GET",
    })
      .then((resp) => {
        if (resp.status === 200) {
          return resp.text();
        } else {
          throw new Error("Failure status code");
        }
      })
      .then((text) => {
        const json = parseJSONWithDates(text);
        console.log("Car:", json);
        setCar(json);
      })
      .catch((err) => {
        console.log(err);
        setCar();
      });
  }, []);

  function saveCarUpdate(car) {
    setIsSaving(true);

    let carCopy = JSON.parse(JSON.stringify(car));

    //Remove mongoDB id to prevent writing to an immutable field
    delete carCopy._id;

    fetch("https://api.corvettetrack.com/cars/" + car.carId, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(carCopy),
    })
      .then((resp) => {
        if (resp.status === 200) {
          console.log("Updated car in db!");
        }
        setTimeout(() => setIsSaving(false), 2000);
      })
      .catch((err) => {
        setTimeout(() => setIsSaving(false), 2000);
        console.log(err);
      });
  }

  function applyBuildUpdate(key, value) {
    let carCopy = JSON.parse(JSON.stringify(car));

    if ("build" in carCopy) {
      carCopy.build[key] = value;
    } else {
      carCopy.build = { key: value };
    }

    saveCarUpdate(carCopy);
    setCar(carCopy);
  }

  function applyOrderDateIfValid(key, object) {
    if (object && "$d" in object) {
      const date = object.isValid() ? object["$d"] : null;

      let carCopy = JSON.parse(JSON.stringify(car));

      if ("build" in carCopy) {
        carCopy.orderDates[key] = date;
      } else {
        carCopy.orderDates = { key: date };
      }

      saveCarUpdate(carCopy);
      setCar(carCopy);
    }
  }

  return (
    <Box sx={{ textAlign: "center", mb: "64px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "end",
          position: "absolute",
          top: "60px",
          right: "16px",
        }}
      >
        {isSaving ? (
          <CircularProgress color="torchRed" />
        ) : (
          <Typography color={"#888"}>Car automatically saved.</Typography>
        )}
      </Box>
      <Typography
        sx={{ marginTop: "32px", mb: "16px" }}
        fontWeight="bold"
        variant="h5"
      >
        My Car
      </Typography>
      <Typography>
        Fill this out as completely as possible for a more accurate order
        timeline estimate.
      </Typography>
      {car ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            marginTop: "32px",
            gap: "32px",
          }}
        >
          <Card
            elevation={4}
            sx={{
              width: "25vw",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "16px",
            }}
          >
            <Typography fontWeight={"bold"}>Build</Typography>
            <Typography sx={{ marginBottom: "32px" }}>
              More options coming soon
            </Typography>
            <FormControl sx={{ width: "50%" }}>
              <InputLabel>Model</InputLabel>
              <Select
                value={car.build.model}
                onChange={(e) => applyBuildUpdate("model", e.target.value)}
                sx={{ minWidth: "50%" }}
                label="Model"
              >
                <MenuItem value="stingray">Stingray</MenuItem>
                <MenuItem value="z06">Z06</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ marginTop: "12px", width: "50%" }}>
              <InputLabel>Trim</InputLabel>
              <Select
                value={car.build.trim}
                onChange={(e) => applyBuildUpdate("trim", e.target.value)}
                sx={{ minWidth: "50%" }}
                label="Trim"
              >
                <MenuItem value={"1lt"}>{isZ06 ? "1LZ" : "1LT"}</MenuItem>
                <MenuItem value={"2lt"}>{isZ06 ? "2LZ" : "2LT"}</MenuItem>
                <MenuItem value={"3lt"}>{isZ06 ? "3LZ" : "3LT"}</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ marginTop: "12px", width: "50%" }}>
              <InputLabel>Body</InputLabel>
              <Select
                value={car.build.body}
                onChange={(e) => applyBuildUpdate("body", e.target.value)}
                sx={{ minWidth: "50%" }}
                label="Body"
              >
                <MenuItem value="coupe">Coupe</MenuItem>
                <MenuItem value="htc">HTC</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ marginTop: "12px", width: "50%" }}>
              <InputLabel>Exterior Color</InputLabel>
              <Select
                value={car.build.exteriorColor}
                onChange={(e) =>
                  applyBuildUpdate("exteriorColor", e.target.value)
                }
                sx={{ minWidth: "50%" }}
                label="Exterior Color"
              >
                {exteriorColorCodes.map((code) => (
                  <MenuItem key={code} value={code}>
                    {nameForExteriorColor(code)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ marginTop: "12px", width: "50%" }}>
              <InputLabel>Wheels</InputLabel>
              <Select
                value={car.build.wheels}
                onChange={(e) => applyBuildUpdate("wheels", e.target.value)}
                sx={{ minWidth: "50%" }}
                label="Wheels"
              >
                {wheelCodes.map((code) => (
                  <MenuItem key={code} value={code}>
                    {code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ marginTop: "12px", width: "50%" }}>
              <InputLabel>Z51</InputLabel>
              <Select
                value={car.build.hasZ51}
                onChange={(e) => applyBuildUpdate("hasZ51", e.target.value)}
                sx={{ minWidth: "50%" }}
                label="Z51"
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ marginTop: "12px", width: "50%" }}>
              <InputLabel>MagRide</InputLabel>
              <Select
                value={car.build.hasMagRide}
                onChange={(e) => applyBuildUpdate("hasMagRide", e.target.value)}
                sx={{ minWidth: "50%" }}
                label="MagRide"
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ marginTop: "12px", width: "50%" }}>
              <InputLabel>Front Lift</InputLabel>
              <Select
                value={car.build.hasFrontLift}
                onChange={(e) =>
                  applyBuildUpdate("hasFrontLift", e.target.value)
                }
                sx={{ minWidth: "50%" }}
                label="Front Lift"
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ marginTop: "12px", width: "50%" }}>
              <InputLabel>Engine Appearance</InputLabel>
              <Select
                value={car.build.hasEngineAppearance}
                onChange={(e) =>
                  applyBuildUpdate("hasEngineAppearance", e.target.value)
                }
                sx={{ minWidth: "50%" }}
                label="Engine Appearance"
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              sx={{ marginTop: "12px", width: "50%", marginBottom: "16px" }}
            >
              <InputLabel>Museum Delivery</InputLabel>
              <Select
                value={car.build.hasMuseumDelivery}
                onChange={(e) =>
                  applyBuildUpdate("hasMuseumDelivery", e.target.value)
                }
                sx={{ minWidth: "50%" }}
                label="Museum Delivery"
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </Card>
          <Card
            elevation={4}
            sx={{ width: "25vw", paddingTop: "16px", paddingBottom: "16px" }}
          >
            <Typography fontWeight={"bold"}>Order Status Dates</Typography>
            <Typography sx={{ marginBottom: "32px" }}>
              Fill these in as you reach each status
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={
                  hasOrderDates && car.orderDates["3000"]
                    ? dayjs(car.orderDates["3000"])
                    : undefined
                }
                sx={{ marginBottom: "8px" }}
                label="3000 (Accepted)"
                onChange={(o) => applyOrderDateIfValid("3000", o)}
              />
              <DatePicker
                value={
                  hasOrderDates && car.orderDates["3300"]
                    ? dayjs(car.orderDates["3300"])
                    : undefined
                }
                sx={{ marginBottom: "8px" }}
                label="3300 (Scheduled)"
                onChange={(o) => applyOrderDateIfValid("3300", o)}
              />
              <DatePicker
                value={
                  hasOrderDates && car.orderDates["3400"]
                    ? dayjs(car.orderDates["3400"])
                    : undefined
                }
                sx={{ marginBottom: "8px" }}
                label="3400 (Broadcast)"
                onChange={(o) => applyOrderDateIfValid("3400", o)}
              />
              <DatePicker
                value={
                  hasOrderDates && car.orderDates["3800"]
                    ? dayjs(car.orderDates["3800"])
                    : undefined
                }
                sx={{ marginBottom: "8px" }}
                label="3800 (Produced)"
                onChange={(o) => applyOrderDateIfValid("3800", o)}
              />
              <DatePicker
                value={
                  hasOrderDates && car.orderDates["4000"]
                    ? dayjs(car.orderDates["4000"])
                    : undefined
                }
                onChange={(o) => applyOrderDateIfValid("4000", o)}
                sx={{ marginBottom: "8px" }}
                label="4000 (Rdy to Ship)"
              />
              <DatePicker
                value={
                  hasOrderDates && car.orderDates["6000"]
                    ? dayjs(car.orderDates["6000"])
                    : undefined
                }
                sx={{ marginBottom: "8px" }}
                label="6000 (Delivered)"
                onChange={(o) => applyOrderDateIfValid("6000", o)}
              />
            </LocalizationProvider>
          </Card>
          <Card
            elevation={4}
            sx={{ width: "25vw", paddingTop: "16px", paddingBottom: "16px" }}
          >
            <Typography fontWeight={"bold"}>Production (TPW & VIN)</Typography>
            <Typography sx={{ marginBottom: "32px" }}>
              Fill these in as you get them
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={
                  hasOrderDates && car.orderDates["initialTpw"]
                    ? dayjs(car.orderDates["initialTpw"])
                    : undefined
                }
                onChange={(o) => applyOrderDateIfValid("initialTpw", o)}
                sx={{ marginBottom: "8px" }}
                label="Initial TPW"
              />
              <DatePicker
                value={
                  hasOrderDates && car.orderDates["currentTpw"]
                    ? dayjs(car.orderDates["currentTpw"])
                    : undefined
                }
                onChange={(o) => applyOrderDateIfValid("currentTpw", o)}
                sx={{ marginBottom: "8px" }}
                label="Current TPW"
              />
              <DatePicker
                value={
                  hasOrderDates && car.orderDates["actualPw"]
                    ? dayjs(car.orderDates["actualPw"])
                    : undefined
                }
                onChange={(o) => applyOrderDateIfValid("actualPw", o)}
                sx={{ marginBottom: "8px" }}
                label="Actual PW"
              />
              <FormControl sx={{ width: "53%" }}>
                <TextField
                  value={car.build.vin_first_5}
                  onChange={(e) =>
                    applyBuildUpdate(
                      "vin_first_5",
                      e.target.value.length > 0
                        ? e.target.value.slice(0, 5)
                        : null
                    )
                  }
                  sx={{ minWidth: "50%" }}
                  label="VIN First 5 Digits"
                />
              </FormControl>
            </LocalizationProvider>
          </Card>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="torchRed" />
        </Box>
      )}
    </Box>
  );
}
