export const exteriorColorCodes = [
  "aot",
  "aw",
  "aym",
  "bl",
  "cfm",
  "cg",
  "cmg",
  "hgm",
  "rb",
  "rmm",
  "rbm",
  "sfm",
  "swg",
  "tr",
];

export function colorForExteriorColor(color) {
  if (color === "aot") {
    return "orange";
  } else if (color === "aw") {
    return "#EFEFEF";
  } else if (color === "aym") {
    return "#fdcc0d";
  } else if (color === "bl") {
    return "black";
  } else if (color === "cfm") {
    return "#1e2522";
  } else if (color === "cg") {
    return "#274739";
  } else if (color === "cmg") {
    return "#8E8E8E";
  } else if (color === "hgm") {
    return "#7a7f80";
  } else if (color === "rb") {
    return "#2da4f4";
  } else if (color === "rmm") {
    return "#612020";
  } else if (color === "rbm") {
    return "#194172";
  } else if (color === "sfm") {
    return "#aaa9ad";
  } else if (color === "swg") {
    return "#919494";
  } else if (color === "tr") {
    return "#c2211a";
  }
}

export function nameForExteriorColor(color) {
  if (color === "aot") {
    return "Amplify Orange Tintcoat";
  } else if (color === "aw") {
    return "Artic White";
  } else if (color === "aym") {
    return "Accelerate Yellow Metallic";
  } else if (color === "bl") {
    return "Black";
  } else if (color === "cfm") {
    return "Carbon Flash Metallic";
  } else if (color === "cg") {
    return "Cacti Green";
  } else if (color === "cmg") {
    return "Ceramic Matrix Gray Metallic";
  } else if (color === "hgm") {
    return "Hypersonic Gray Metallic";
  } else if (color === "rb") {
    return "Rapid Blue";
  } else if (color === "rmm") {
    return "Red Mist Metallic Tintcoat";
  } else if (color === "rbm") {
    return "Riptide Blue Metallic";
  } else if (color === "sfm") {
    return "Silver Flare Metallic";
  } else if (color === "swg") {
    return "Sea Wolf Gray Tricoat";
  } else if (color === "tr") {
    return "Torch Red";
  }
}
