import {
  AppBar,
  Box,
  Button,
  ThemeProvider,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import Welcome from "./components/pages/Welcome";
import Footer from "./components/Footer";
import { theme } from "./utility/theme";
import {
  createBrowserRouter,
  Link,
  redirect,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { CreateAccount } from "./components/pages/CreateAccount";
import { useState } from "react";
import { Login } from "./components/pages/Login";
import { Home } from "./components/pages/Home";
import { Analytics } from "./components/pages/Analytics/Analytics";
import { MyCar } from "./components/pages/MyCar/MyCar";
import { OrderTimeline } from "./components/pages/OrderTimeline";

function App() {
  const [userId, setUserId] = useState(localStorage.getItem("userId"));

  function handleLogin(userId) {
    setUserId(userId);
    localStorage.setItem("userId", userId);
  }

  function handleLogout() {
    setUserId();
    window.location.pathname = "/";
    localStorage.removeItem("userId");
  }

  const noAuthRouter = createBrowserRouter([
    {
      path: "/",
      element: <Welcome />,
    },
    {
      path: "my-car",
      element: <Welcome />,
    },
    {
      path: "/create-account",
      element: <CreateAccount handleLogin={handleLogin} />,
    },
    {
      path: "/login",
      element: <Login handleLogin={handleLogin} />,
    },
  ]);

  const authRouter = createBrowserRouter([
    {
      path: "/",
      element: <MyCar userId={userId} />,
    },
    {
      path: "my-car",
      element: <MyCar userId={userId} />,
    },
    {
      path: "order-timeline",
      element: <OrderTimeline userId={userId} />,
    },
    {
      path: "/analytics",
      element: <Analytics userId={userId} />,
    },
  ]);

  return (
    <Box>
      <ThemeProvider theme={theme}>
        {userId ? (
          <Box>
            <AppBar
              color="torchRed"
              position="static"
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <Button
                onClick={() => {
                  window.location.pathname = "/my-car";
                }}
                style={{
                  marginLeft: "16px",
                  color: "white",
                }}
              >
                My Car
              </Button>
              <Button
                onClick={() => {
                  window.location.pathname = "/order-timeline";
                }}
                style={{
                  marginLeft: "32px",
                  color: "white",
                }}
              >
                Order Timeline
              </Button>
              <Button
                onClick={() => {
                  window.location.pathname = "/analytics";
                }}
                style={{
                  marginLeft: "32px",
                  color: "white",
                }}
              >
                Analytics
              </Button>
              <Button
                onClick={handleLogout}
                style={{
                  marginLeft: "auto",
                  marginBottom: 0,
                  marginRight: "16px",
                  color: "white",
                }}
              >
                Log Out
              </Button>
            </AppBar>
            <RouterProvider router={authRouter} />
          </Box>
        ) : (
          <Box>
            <AppBar color="torchRed" position="static">
              <Button
                onClick={() => {
                  window.location.pathname = "/login";
                }}
                style={{
                  marginLeft: "auto",
                  marginBottom: 0,
                  marginRight: "16px",
                  color: "white",
                }}
              >
                Log In
              </Button>
            </AppBar>
            <RouterProvider router={noAuthRouter} />
          </Box>
        )}
      </ThemeProvider>
    </Box>
  );
}

export default App;
