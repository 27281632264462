import { Box, Button, Card, Typography } from "@mui/material";
import Footer from "../Footer";
import { useNavigate } from "react-router";

export default function Welcome() {
  const navigate = useNavigate();

  return (
    <Box>
      <Box
        sx={{
          textAlign: "center",
          height: "90vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            backgroundImage: `url(/assets/corvette_track_hah.jpeg)`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100vw",
            height: "100vh",
            zIndex: -1,
            opacity: 0.15,
          }}
        />

        <Box>
          <Typography variant="h2" fontWeight="bold">
            CorvetteTrack
          </Typography>
          <Box sx={{ mt: "16px" }}>
            <Typography variant="body">
              A website that answers the question every Corvette orderer has:
            </Typography>
          </Box>

          <Box sx={{ mt: "8px" }}>
            <Typography fontWeight={"bold"} variant="body">
              How long until...?
            </Typography>
          </Box>
          <Box
            sx={{
              mt: "48px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Button
              onClick={() => navigate("/create-account")}
              fontWeight="bold"
              color="torchRed"
              variant="contained"
            >
              Create Account
            </Button>
            <Typography fontWeight="bold">to get started</Typography>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
