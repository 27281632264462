export const wheelCodes = [
  "Q8P",
  "Q8Q",
  "Q8S",
  "Q8T",
  "Q99",
  "Q9A",
  "Q9I",
  "Q9Y",
  "Q9O",
  "Q9Q",
  "SOE",
  "SOD",
];
