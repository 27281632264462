export const apiUrl = "https://api.corvettetrack.com/";

const regexIso8601 =
  /(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2}).(\d{3})Z/;

function reviveDates(key, value) {
  if (typeof value === "string" && regexIso8601.test(value)) {
    return new Date(value);
  }
  return value;
}

export function parseJSONWithDates(s) {
  return JSON.parse(s, reviveDates);
}
