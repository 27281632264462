import { Box, CircularProgress, Typography } from "@mui/material";
import { ExteriorColors } from "./ExteriorColors";
import { useEffect, useState } from "react";
import { Wheels } from "./Wheels";
import { Model } from "./Model";
import { Trim } from "./Trim";
import { Body } from "./Body";
import { HasZ51 } from "./HasZ51";
import { HasMagRide } from "./HasMagRide";
import { HasFrontLift } from "./HasFrontLift";
import { HasMuseumDelivery } from "./HasMuseumDelivery";

export function Analytics() {
  const [analytics, setAnalytics] = useState();

  useEffect(() => {
    fetch("https://api.corvettetrack.com/cars/buildAnalytics", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ date_3000_start: new Date(2020, 1, 1) }),
    })
      .then((resp) => resp.json())
      .then((json) => {
        console.log(json);
        setAnalytics(json);
      })
      .catch((err) => {
        console.log(err);
        setAnalytics();
      });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {analytics ? (
        <Box sx={{ textAlign: "center" }}>
          <Typography
            sx={{ marginTop: "32px", mb: "16px" }}
            fontWeight="bold"
            variant="h5"
          >
            Analytics
          </Typography>
          <Typography sx={{ mb: "64px" }}>
            Based on all orders in the CorvetteTrack database.
          </Typography>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            <ExteriorColors exteriorColor={analytics.exteriorColor} />
            <Model model={analytics.model} />
            <Trim trim={analytics.trim} />
            <Body body={analytics.body} />
            <Wheels wheels={analytics.wheels} />
            <HasZ51 hasZ51={analytics.hasZ51} />
            <HasMagRide hasMagRide={analytics.hasMagRide} />
            <HasFrontLift hasFrontLift={analytics.hasFrontLift} />
            <HasMuseumDelivery
              hasMuseumDelivery={analytics.hasMuseumDelivery}
            />
          </Box>
          <Typography sx={{ color: "#AAA" }}>
            Analytics for all options coming soon...
          </Typography>
        </Box>
      ) : (
        <Box>
          <CircularProgress color="torchRed" />
        </Box>
      )}
    </Box>
  );
}
