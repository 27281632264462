import { createTheme } from "@mui/material";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
export const theme = createTheme({
  palette: {
    torchRed: createColor("#F10000"),
  },
  typography: {
    button: {
      fontSize: 16,
      fontWeight: "bold",
    },
  },
});
